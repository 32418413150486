<template>
	<div class="margin-t-20 width-fill">
		<div v-loading="loading" :class="themeClass" class="width-fill bg-fff my-account">
			<div class="title font-size18 font-weight700" v-if="getUserInfo.fCommerceTypeID == 2">账服通银行账户</div>
			<div class="font-size12 bgc-pink font-color-FF9900 width-fill flex-row" v-if="getUserInfo.fCommerceTypeID == 2"><i
				 class="iconfont flex-row-center-center margin-r-5 icon-tishi5 font-size18"></i>提示:
				账服通银行账户是为账服通钱包进行提现操作的现金接收账户，绑定后可在提现时提现至绑定的的银行账户。</div>
			<div class="title font-size18 font-weight700" v-if="getUserInfo.fCommerceTypeID == 1">账服通银行卡</div>
			<el-tabs v-if="getUserInfo.fCommerceTypeID == 1" v-model="activeTabName" @tab-click="handleClicktab">
				<el-tab-pane v-if="getUserInfo.fCommerceTypeID == 1" class="pointer-color-theme" label="充值账户"></el-tab-pane>
				<el-tab-pane v-if="getUserInfo.fCommerceTypeID == 1" class="pointer-color-theme" label="提现账户"></el-tab-pane>
			</el-tabs>
			<div class="font-size12 bgc-pink font-color-FF9900 width-fill flex-row" v-if="getUserInfo.fCommerceTypeID == 1"><i
				 class="iconfont flex-row-center-center margin-r-5 icon-tishi5 font-size18"></i>
				<div v-if="activeTabName==0">提示: 账服通充值账户是为账服通钱包进行充值的快捷充值账户，绑定后可进行账服通快捷充值，充值后现金钱包余额可进行购买非自营店的商品。</div>
				<div v-if="activeTabName==1">提示: 账服通提现账户是为账服通钱包进行提现操作的现金接收银行卡，绑定后可在提现时选择您想要提现至的银行卡。</div>
			</div>
			<div class="content flex-row-wrap">
				<div class="bottom  margin-t-15" v-for="(item,index) of rechargeACout" :key="index">
					<div class="card radius10" v-if="item.activate==false || item.activate==undefined">
						<div class="card-top" :class="{'thcls':item.type=='提现账户','chcls':item.type=='充值账户'}">
							<div class="bank flex-row">
								<div class="bank-icon flex-row-center-center">
									<svg-icon icon-class="UnionPay" style="height: 30px;" />
								</div>
								<div class="bank-name font-size14  margin-r-5 font-weight700">{{item.fBankName}}</div>
								<div class="card-type text-center font-size12 background-color-theme spantype" :class="{'fzh':item.type=='充值账户'}">{{item.type}}</div>
								<div v-if="getUserInfo.fCommerceTypeID==2" class="unbundling1 pointer fonrc1" @click="ChangeOfAccount(item.fAccountID)">变更</div>
								<div class="unbundling font-color-fff pointer" @click="unbundling(item.fAccountID)">解绑</div>
							</div>
							<div class="cardID font-color-fff font-size18 font-weight700" v-html="$options.filters.BankCardFormat(item.fAccountNumber)"></div>
							<div class="font-size12 margin-t-10 " :class="{'font-color-fff':item.fIsDefault==0,'fonth':item.fIsDefault==1}"><span
								 class="pointer" @click="setMos(item.fAccountID)">{{item.fIsDefault==0?'设为默认':'默认'}}</span></div>
							<!-- <img src="../../../assets/u298.png"></img> -->
						</div>
					</div>

					<div class="card radius10" v-if="item.activate==true && getUserInfo.fCommerceTypeID==2">
						<div class="card-top" :class="{'thcls':item.type=='提现账户','chcls':item.type=='充值账户'}">
							<div class="bank flex-row">
								<div class="bank-icon flex-row-center-center">
									<svg-icon icon-class="UnionPay" style="height: 30px;" />
								</div>
								<div class="bank-name font-size14  margin-r-5 font-weight700">{{item.fBankName}}</div>
								<div class="card-type text-center font-size12 background-color-theme spantype" :class="{'fzh':item.type=='充值账户'}">{{item.type}}</div>
								<div v-if="item.fCanDelete==0" class="unbundling font-color-fff pointer fonrc" @click="goValidate(item.fAccountID)">未激活</div>
								<div v-if="item.fCanDelete==1" class="unbundling font-color-fff pointer fonrc2" @click="deleteBindingRecord(item)">删除</div>
							</div>
							<div class="cardID font-color-fff font-size18 font-weight700" v-html="$options.filters.BankCardFormat(item.fAccountNumber)"></div>
							<div class="font-size12 margin-t-10 " :class="{'font-color-fff':item.fIsDefault==0,'fonth':item.fIsDefault==1}"></div>
							<!-- <img src="../../../assets/u298.png"></img> -->
						</div>
					</div>

				</div>
				<!-- 展示提现账户 无数据时候 -->
				<!-- 个人两个卡 -->
				<div class="bottom  margin-t-15 " v-if="getUserInfo.fCommerceTypeID==1&&activeTabName=='1'">
					<div class="card radius10 u665">
						<div class="card-top font-weight700 colrm pointer" @click="openAccountPay('绑定提现卡')">+添加提现账户</div>
					</div>
				</div>
				<div class="bottom  margin-t-15" v-if="getUserInfo.fCommerceTypeID==1&&activeTabName=='0'">
					<div class="card radius10 u665">
						<div class="card-top font-weight700 colrm pointer" @click="openAccountPay('绑定充值卡')">+添加充值账户</div>
					</div>
				</div>
				<!-- 企业一个卡 仅有提现 -->
				<div class="bottom  margin-t-15 " v-if="getUserInfo.fCommerceTypeID==2&&rechargeACout.length==0">
					<div class="card radius10 u665">
						<div class="card-top font-weight700 colrm pointer" @click="openAccountPay('绑定提现卡')">+添加提现账户</div>
					</div>
				</div>


			</div>
		</div>
		<el-dialog title="激活提现账户" :visible.sync="dialogFormVisible" width="20%" :close-on-click-modal="false">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
				<el-form-item label="激活金额" :label-width="formLabelWidth" prop="money">
					<el-input v-model="ruleForm.money" autocomplete="off" placeholder="请输入来账金额激活" :required="true"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="validateCancel('ruleForm')">取 消</el-button>
				<el-button type="primary" @click="validateConfirm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import pagination from "@/components/pagination/pagination.vue";
	export default {
		components: {
			pagination
		},
		data() {
			var checkMoney = (rule, value, callback) => {
				if (!/^(?!0+(?:\.0+)?$)(?:[1-9]\d*|0)(?:\.\d{1,2})?$/.test(value)) {
					callback(new Error("金额必须为数字值且最多保留两位小数"));
				} else {
					callback();
				}
			};
			return {
				typeId: 1, // 切换银行卡类型id
				fAccountID: '',
				formLabelWidth: "80px",
				dialogFormVisible: false,
				AccountPayWalletInfo: null, //账服通钱包信息
				acsBankCard: {}, //账服通快捷支付银行卡信息
				acsWithdrawBankCard: {}, //账服通提现账户信息
				activeTabName: '0',
				loading: false,
				rechargeACout: [],
				ruleForm: {
					money: null,
				},
				rules: {
					money: [{
						validator: checkMoney,
						trigger: "blur"
					}],
				},
				B2bAccount: null

			};
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
			...mapGetters(['getUserInfo']),
		},
		mounted() {
			this.getAll();

		},
		methods: {
			setMos(id) {

				let type = this.getUserInfo.fCommerceTypeID;
				let url;
				//个人
				if (type == 1) {
					url = "/api/mall/ebcustomer/bank-account/set-acs-recharge-account-default"
				} else {
					//企业
					url = "/api/mall/ebcustomer/bank-account/set-acs-withdraw-account-default"
				}
				this.ApiRequestPostNOMess(
					url, {
						fAccountID: id
					}
				).then(
					(result) => {
						this.getAcsPhysical();
					},
					(rej) => {}
				);
			},
			unbundling(id) {

				this.$confirm('确定解绑此银行卡吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					console.log(this.typeId)
					if (this.typeId == 1 && this.getUserInfo.fCommerceTypeID == 1) { // 充值
						this.ApiRequestPost(
							"/api/mall/ebcustomer/bank-account/unbundling-acs-charge-account", {
								fAccountID: id
							}
						).then(
							(result) => {
								this.getAcsPhysical('0');
						
							},
							(rej) => {}
						);
					}else if (this.typeId == 2 && this.getUserInfo.fCommerceTypeID == 1) { // 提现
						this.ApiRequestPost(
							"/api/mall/ebcustomer/bank-account/unbundling-acs-account", {
								fAccountID: id
							}
						).then(
							(result) => {
								this.getAcsPhysical();
						
							},
							(rej) => {}
						);
					} else if (this.getUserInfo.fCommerceTypeID == 2) {
						this.ApiRequestPost(
							"/api/mall/ebcustomer/bank-account/unbundling-acs-account", {
								fAccountID: id
							}
						).then(
							(result) => {
								this.getAcsPhysical();
						
							},
							(rej) => {}
						);
					}
					
				}).catch(() => {

				});


			},
			getAll() {

				// this.rechargeACout=[];
				// this.B2bAccount=null;
				if (this.getUserInfo.fCommerceTypeID == 1) {
					this.getAcsPhysical('0');
				} else {
					this.getAcsPhysical();
				}

			},
			// 验证弹框点击确定
			validateConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.ApiRequestPost(
							"/api/mall/ebcustomer/bank-account/validatie-acs-b2b-account", {
								fMoney: Number(this.ruleForm.money),
								fAccountID: this.fAccountID
							}
						).then(
							(result) => {
								this.validateCancel(formName)
								// this.rechargeACout=[];
								// this.B2bAccount=null;
								this.getAll();
							},
							(rej) => {}
						);
					} else {
						return false;
					}
				});
			},
			// 验证弹框点击取消
			validateCancel(formName) {
				this.$refs[formName].resetFields();
				this.dialogFormVisible = false;
			},
			// 企业用户去充值验证是否绑卡成功
			goValidate(id) {
				this.fAccountID = id;
				this.dialogFormVisible = true;
			},
			openAccountPay(val) {

				if (this.getUserInfo.fCommerceTypeID == 1) {
					//跳转个人用户绑卡				
					if (this.activeTabName == '1') {
						this.$router.push({
							path: '/businessme/bandCard',
							query: {
								title: val,
								type: 1,

							}
						})
					} else {
						this.$router.push({
							path: '/businessme/individualUsersBindingCard',
							query: {
								title: val,
								type: 2,
								fAccountname: '账服通'
							}
						})
					}
				} else if (this.getUserInfo.fCommerceTypeID == 2) {
					//跳转企业用户绑卡
					this.$router.push({
						path: '/businessme/enterpriseUsersBindingCard',
						query: {
							title: val,
							type: 2
						}
					})
				}
			},
			//判断账服通是否可以进行绑定新卡
			isActivateAcsB2bAccount() {
				this.ApiRequestPost(
					"/api/mall/ebcustomer/bank-account/is-activate-acs-b2b-account"

				).then(
					(result) => {
						this.B2bAccount = null;
						this.B2bAccount = result.obj;
						if (result.obj) {
							result.obj.type = '提现账户';
							result.obj.activate = true;
							this.rechargeACout.push(result.obj);
						}
					},
					(rej) => {}
				);
			},
			//   获取账服通钱包余额
			getAccountPayWalletInfo() {
				this.ApiRequestPost(
					"api/mall/ebbalance/customer-acs/get-my-ACS"

				).then(
					(result) => {
						if (result.obj == null || result.obj.fCustomerID == null) {
							this.AccountPayWalletInfo = null;
							return;
						}
						this.AccountPayWalletInfo = result.obj;

					},
					(rej) => {}
				);
			},
			//   账服通绑定快捷支付银行卡查询
			getAcsPhysicalAccount() {
				this.ApiRequestPost(
					"api/mall/ebcustomer/bank-account/get-acs-recharge-physical-account"
				).then(
					(result) => {
						// console.log(result.obj, "账服通绑定银行卡查询");
						this.acsBankCard = result.obj;
					},
					(rej) => {}
				);
			},
			//   账服通绑定提现银行卡查询
			getAcsWithdrawPhysicalAccount() {
				this.ApiRequestPost(
					"api/mall/ebcustomer/bank-account/get-acs-withdraw-physical-account"
				).then(
					(result) => {
						// console.log(result.obj, "账服通绑定提现银行卡查询");
						this.acsWithdrawBankCard = result.obj;
					},
					(rej) => {}
				);
			},
			//标签页点击事件
			handleClicktab(tab) {
				this.activeTabName = tab.index;
				if (tab.index == '0') {
					//充值
					this.getAcsPhysical('0');
					this.typeId = 1
				} else {
					this.getAcsPhysical(); // 提现
					this.typeId = 2
				}

			},
			//获取个人卡 充值账户列表
			getAcsPhysical(type) {
				this.loading = true;
				let url;
				//支付
				if (type == '0') {
					url = "/api/mall/ebcustomer/bank-account/get-acs-physical-recharge-account-list"
				} else {
					//提现
					url = "/api/mall/ebcustomer/bank-account/get-acs-physical-withdraw-account-list"
				}
				this.ApiRequestPostNOMess(
					url
				).then(
					(result) => {
						this.rechargeACout = [];
						let arrList = [];
						this.rechargeACout = result.obj;
						for (let item of this.rechargeACout) {
							if (type == '0') {
								item.type = '充值账户'
							} else {
								item.type = '提现账户'
								item.activate = false;
							}
						}
						if (type != '0') {
							this.isActivateAcsB2bAccount()
						}

						// // if(this.activeTabName=='0'){
						// // 	this.activeTabName='0'
						// // }else{
						// 	this.activeTabName='1'
						// // }

						this.loading = false;
					},
					(rej) => {}
				);
			},
			//企业变更账服通提现账户
			ChangeOfAccount(e) {
				this.$router.push({
					path: '/businessme/enterpriseUsersBindingCard',
					query: {
						title: '提现账户信息变更',
						type: '3',
						fAccountname: '账服通',
						fAccount: e
					}
				}) //跳转企业用户绑卡
			},
			//删除卡片
			deleteBindingRecord(e) {
				this.$confirm('是否确认删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.ApiRequestPost("/api/mall/ebcustomer/bank-account/delete-binding-record", {
						fAccountID: e.fAccountID,
						frv: e.frv,
						rowStatus: e.rowStatus
					}).then(
						(result) => {
							this.getAll();
							this.$message.success(result.message);
						},
						(rej) => {}
					);
				}).catch(() => {
				});
			},
		},
		filters: {
			//格式化银行账户
			BankCardFormat: function(value) {
				value = value.toString();
				if (value && value.length > 8) {
					return ("<span style='letter-spacing:1.5px'>" + `${value.substring(0, 4)}` + "</span>" +
						"<span style='margin-left:5px;margin:0px 5px;letter-spacing:1.5px;font-weight:700'>" +
						`${' **** *** **** '}` + "</span>" + "<span style='letter-spacing:1.5px'>" + `${value.slice(-4)}` + "</span>");
				}
				return value
			},
			formatPhone: function(val) {
				return `${val.substring(0,3)}****${val.substring(val.length-3)}`
			}
		},
	}
</script>

<style lang="scss" scoped>
	.fonrc {
		font-weight: bold;
		color: #002bff;
		border-bottom: 1px solid #002bff;
	}

	.fonrc1 {
		font-weight: bold;
		color: #FFFF33;
	}

	.fonrc2 {
		font-weight: bold;
		color: red;
		border-bottom: 1px solid red;
	}

	.fzh {
		background-color: rgba(255, 232, 247, 1) !important;
	}

	.fonth {
		color: #FFFF33
	}

	.colrm {
		text-align: center;
		color: rgb(51, 51, 51) !important;
		margin-top: 10%;
	}

	.u665 {

		background: inherit;
		background-color: rgba(248, 249, 254, 1);
		box-sizing: border-box;
		border-width: 1px;
		border-style: solid;
		border-color: rgba(242, 242, 242, 1);

	}

	.thcls {
		// background-image: url(img/qbbg.751de4d4.png);
		// background-image: url('~@/assets/th.png');
		background-image: url(../../../assets/th.png);
		// background: linear-gradient(
		// 135deg
		// , rgba(29, 130, 254, 1) 0%, rgba(29, 130, 254, 1) 0%, rgba(80, 158, 254, 1) 52%, rgba(151, 205, 255, 1) 100%, rgba(151, 205, 255, 1) 100%);
		// background: -webkit-linear-gradient(
		// 315deg
		// , rgba(29, 130, 254, 1) 0%, rgba(29, 130, 254, 1) 0%, rgba(80, 158, 254, 1) 52%, rgba(151, 205, 255, 1) 100%, rgba(151, 205, 255, 1) 100%);
	}

	.chcls {
		// background-image: url('~@/assets/chz.png');
		background-image: url(../../../assets/chz.png);
		// background: -webkit-linear-gradient(
		// 315deg
		// , rgba(0, 196, 186, 1) 0%, rgba(0, 196, 186, 1) 0%, rgba(0, 202, 213, 1) 52%, rgba(2, 223, 218, 1) 100%, rgba(2, 223, 218, 1) 100%);
		// background: linear-gradient(
		// 135deg
		// , rgba(0, 196, 186, 1) 0%, rgba(0, 196, 186, 1) 0%, rgba(0, 202, 213, 1) 52%, rgba(2, 223, 218, 1) 100%, rgba(2, 223, 218, 1) 100%);
	}

	.spantype {
		padding-left: 2px;
		padding-right: 2px;
		border-width: 0px;
		left: 0px;
		top: 0px;
		width: 48px;
		height: 17px;
		background: inherit;
		background-color: rgba(253, 240, 198, 1);
		border: none;
		border-radius: 2px;
		-moz-box-shadow: none;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: 12px;
		color: #000000;
	}

	.content {

		// width: 440px;
		.title {
			height: 24px;
			line-height: 24px;
			margin-top: 8px;
			margin-left: 15px;
		}

		.top {
			height: 199px;

			.left {
				width: 160px;

				.btn-open {
					width: 160px;
					height: 40px;
					line-height: 40px;
					opacity: 1;
					background: #2e66f8;
					font-family: '阿里巴巴普惠体', '阿里巴巴普惠体'-400;
				}

				.num {
					height: 24px;
					line-height: 24px;
					opacity: 1;
				}

				.text {
					height: 17px;
					line-height: 17px;
				}
			}

			.right {
				height: 167px;
				flex: 1;

				.svgimage {
					width: 179px;
					height: 151px;
				}
			}
		}

		.bottom {

			// height: 230px;
			.card {
				width: 292px;
				height: 110px;
				opacity: 1;
				// background: #e1efff;
				overflow: hidden;
				margin-right: 15px;

				.card-top {
					// width: 405px;
					border-color: rgba(221, 235, 255, 1);
					color: #FFFFFF;
					height: 110px;
					opacity: 1;
					// background: #f8f9fe;
					box-sizing: border-box;
					padding: 15px;

					.bank {
						position: relative;
						height: 19px;
						line-height: 19px;

						.bank-icon {
							width: 34px !important;
							height: 18px !important;
						}

						.bank-name {
							margin-left: 5px;
						}
					}

					.cardID {
						height: 24px;
						margin-top: 12px;
					}
				}

				.userinfo {
					padding-left: 19px;
					padding-top: 14px;
				}

				.unbundling {
					// margin-right: 21px;
					height: 16px;
					line-height: 16px;
					position: absolute;
					right: 0;
				}

				.unbundling1 {
					// margin-right: 21px;
					height: 16px;
					line-height: 16px;
					position: absolute;
					right: 38px;
				}

			}
		}
	}

	.bgc-pink {
		height: 30px;
		background: rgba(255, 245, 223, 1);
		margin-top: 10px;
		line-height: 30px;
		padding: 0 10px;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background-color: transparent;
	}
</style>
